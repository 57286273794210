<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <section
      v-if="isBeforeMounted"
      class="firstTouch"
    >
      <div class="firstTouch_container">
        <img src="@/assets/about/eye-catching-drop.png">
      </div>
    </section>
    <section
      v-else
      class="skintype"
    >
      <div class="skintype_container">
        <div
          v-if="latestSkinTypeId > 0 && latestSkinTypeId != 999"
          class="skintype_img_container"
        >
          <img
            class="skintype_img"
            :src="
              ROOT_SKIN_IMAGE +
              'flow-images/skin-type-diag/result-images/' +
              latestSkinTypeId +
              '/' +
              latestSkinTypeId +
              '_1.png'
            "
          >
          <img
            v-show="isShowingMore"
            v-for="i in imageCount - 1"
            :key="i"
            :src="
              ROOT_SKIN_IMAGE +
              'flow-images/skin-type-diag/result-images/' +
              latestSkinTypeId +
              '/' +
              latestSkinTypeId +
              '_' +
              (i + 1) +
              '.png'
            "
            class="skintype_img"
          >
          <div class="show_more_btn_container">
            <button
              v-show="!isShowingMore"
              class="show_more_btn"
              @click="isShowingMore = true"
            >
              ▼&nbsp;もっと見る
            </button>
            <button
              v-show="isShowingMore"
              class="show_less_btn"
              @click="isShowingMore = false"
            >
              ▲&nbsp;閉じる
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="latestSkinTypeId == 999"
        class="skintype_diag_notyet"
      >
        <p>
          まだ肌質診断を受けていただいておりません。<br>
          まずは自分の肌について知ることが大切。<br>
          肌質診断を受けてみましょう。
        </p>
        <div class="skintypeCheck_btn">
          <router-link to="/flow/skin-type-diag">
            &nbsp;肌質診断をする&nbsp;
          </router-link>
        </div>
      </div>
      <div class="dermatologist_inCarge_container">
        <div class="dermatologist_inCarge_inner">
          <h2>担当医</h2>
          <img
            :src="ROOT_IMAGE_URL
              + '/face-icon/hamano.png'
            "
          >
          <h3>濱野英明</h3>
          <div class="dermatologist_profile">
            <p>テティス横濱美容皮膚科&nbsp;&nbsp;院長</p>
            <p>日本スキンケア協会・皮膚科顧問</p>
            <p>2015ミス・ユニバース&nbsp;ビューティーキャンプ講師</p>
          </div>
          <div class="profile_btn_container">
            <router-link
              :to="{
                name: 'Concierges',
                params: { incomingGenre: 'skintype' },
              }"
              class="profile_btn"
            >
              プロフィールの詳細はこちら
            </router-link>
          </div>
        </div>
      </div>
      <div
        v-if="latestSkinTypeId != 999"
        class="guiding_section_inner"
      >
        <div class="guiding_section_toChatbot">
          <p>
            人の肌は季節ごとに変化します。<br>
            肌質診断を受けてみませんか？
          </p>
          <router-link to="/flow/skin-type-diag">
            もう一度肌質診断をする
          </router-link>
        </div>
      </div>
    </section>
  </transition>
</template>
<script>
import axios from "axios";
import liff from "@line/liff";

export default {
  name: "MyKarteSkinType",
  data() {
    return {
      latestSkinTypeId: 0,
      imageCount: 0,
      ROOT_SKIN_IMAGE: process.env.VUE_APP_BASE_URL,
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
      isShowingMore: false,
      isBeforeMounted: true,
      hasPreviousResult: false
    };
  },
  created() {
    const mydata_target_url = process.env.VUE_APP_MYDATA_URL;
    const id_token = liff.getIDToken();
    axios
      .post(mydata_target_url, {
        liff_id: process.env.VUE_APP_LIFF_ID,
        skin_type_diag: true,
        skincare: { request: false },
        makeup: { request: false },
        id_token: id_token,
      })
      .then((res) => {
        if ("skin_type" in res.data) {
          if(res.data.skin_type.latest_skin_type_id==999){
            this.hasPreviousResult = false
          }else{
            this.hasPreviousResult = true
          }
          this.setMountedSituation()
          this.latestSkinTypeId = res.data.skin_type.latest_skin_type_id;
          this.imageCount = res.data.skin_type.image_count;
        }
        this.isBeforeMounted = false;
      })
      .catch((err) => {
        console.log("Mykarte", err.code, err.message);
        this.isBeforeMounted = false;
      });
  },
  methods: {
    setMountedSituation: function(){
      this.$emit('setSituation', this.hasPreviousResult);
    }
  }
};
</script>
<style scoped>
.skintype {
  background-color: #f5eae7;
  padding: 0 20px;
}
.skintype_container {
  padding: 30px 0 50px;
}
.skintype_img_container {
  padding-bottom: 30px;
  position: relative;
  background-color: #ffffff;
}
.skintype_img {
  width: 100%;
  object-fit: cover;
  padding: 30px 0;
}
.skintype_diag_notyet {
  line-height: 1.3;
  padding: 0 20px;
}
.skintype_diag_notyet p {
  padding: 20px 0 50px;
  font-size: 18px;
  color: #777777;
}
.skintypeCheck_btn {
  padding-bottom: 42px;
}
.skintypeCheck_btn a {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: bold;
  background-color: #fadad2;
  text-align: center;
  color: #444444;
}
.show_more_btn_container {
  text-align: center;
}
.show_more_btn {
  display: inline-block;
  padding: 16px 35px;
  border: #f29f8f solid 1px;
}
.show_less_btn {
  display: inline-block;
  padding: 16px 35px;
  border: #f29f8f solid 1px;
}
.dermatologist_inCarge_container {
  background-color: #f5eae7;
  padding: 0 20px;
}
.skintype_result_inner {
  padding: 0 40px 60px 40px;
  position: relative;
}
.dermatologist_inCarge_inner h2 {
  font-size: 22px;
  font-weight: bold;
}
.dermatologist_inCarge_inner img {
  padding: 10px 0;
  width: 100%;
  object-fit: cover;
}
.dermatologist_inCarge_inner h3 {
  font-size: 20px;
}
.dermatologist_profile {
  padding: 10px 0 24px 0;
}
.profile_btn_container {
  text-align: center;
}
.profile_btn {
  color: #707070;
  background-color: #fadad2;
  padding: 11px 21px;
  font-size: 14px;
  display: inline-block;
}

.see_more {
  padding: 10px 16px;
  border: solid 1px #f29f8f;
  margin-top: 20px;
  display: inline-block;
}
.guiding_section_inner {
  padding: 100px 20px 0;
  text-align: center;
  background-color: #f5eae7;
}
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
router-link {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
.guiding_section_toChatbot p {
  padding-bottom: 10px;
}
.guiding_section_toChatbot a {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  color: #444444;
  background-color: #fadad2;
  font-weight: bold;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.firstTouch {
  background-color: #ffffff;
  padding: 0 40px;
}
.firstTouch_container {
  padding: 500px 0 50px 0;
  text-align: right;
}
.firstTouch_container h1 {
  font-size: 23pxß;
  display: inline-block;
  text-align: center;
  padding-bottom: 45px;
  line-height: 1.7;
}
.firstTouch_container img {
  width: 170px;
  padding: 0 15px 50px 0;
  object-fit: cover;
}
</style>
