module.exports = [
  { id: '1', name: '下地', code: 'base' },
  { id: '2', name: 'ファンデーション', code: 'foundation' },
  { id: '3', name: 'ハイライト', code: 'highlight' },
  { id: '4', name: 'シェーディング', code: 'shading' },
  { id: '5', name: 'コンシーラー', code: 'concealer' },
  { id: '6', name: 'フェイスパウダー', code: 'facepowder' },
  { id: '7', name: 'チーク', code: 'cheek' },
  { id: '8', name: 'アイシャドウ', code: 'eyeshadow' },
  { id: '9', name: 'アイライナー', code: 'eyeliner' },
  { id: '10', name: 'マスカラ', code: 'mascara' },
  { id: '11', name: 'アイブロウ（ペンシル）', code: 'pencil_eyebrow' },
  { id: '12', name: 'アイブロウ（パウダー）', code: 'powder_eyebrow' },
]
