<template>
  <main>
    <section class="mykarte_title">
      <div class="mykarte_title_container">
        <h1>マイカルテ</h1>
        <p>こちらではあなたの肌のデータや、コンシェルジュが提案させていただいたアイテムが確認できます。</p>
      </div>
    </section>
    <div
      v-if="hasPreviousResult"
      class="genre_container"
    >
      <div
        class="genre"
        :class="[selectedGenre === 'SkinTypeDiag' ? 'genre_selected' : '']"
        @click="changeTab('SkinTypeDiag')"
      >
        肌質
      </div>
      <div
        class="genre"
        :class="[selectedGenre === 'SkinCare' ? 'genre_selected' : '']"
        @click="changeTab('SkinCare')"
      >
        スキンケア
      </div>
      <div
        class="genre"
        :class="[selectedGenre === 'Makeup' ? 'genre_selected' : '']"
        @click="changeTab('Makeup')"
      >
        メイク
      </div>
    </div>
    <transition
      name="fade"
      mode="out-in"
    >
      <skin-type
        v-if="selectedGenre==='SkinTypeDiag'"
        @setSituation="setSituation"
      />
    </transition>
    <skin-care v-if="selectedGenre==='SkinCare'" />
    <makeup v-if="selectedGenre==='Makeup'" />
  </main>
</template>
<script>
import SkinType from "./MyKarteAreas/SkinType.vue"
import SkinCare from "./MyKarteAreas/SkinCare.vue"
import Makeup from "./MyKarteAreas/Makeup.vue"

export default {
  name: 'Mydata',
  components: {
    SkinType,
    SkinCare,
    Makeup
  },
  data() {
    return {
      // 初期設定
      count: 0,
      hasPreviousResult: false,
      selectedGenre: "SkinTypeDiag"
    }
  },
  methods: {
    setSituation: function(hasPreviousResult){
      this.hasPreviousResult = hasPreviousResult
    },
    changeTab(genre){
      if(this.selectedGenre==genre) return
      this.selectedGenre = genre
      this.$gtag.pageview({
        page_title: "Mykarte-" + this.selectedGenre,
        page_path: "/Mykarte"
      })
    }
  }
}
</script>
<style scoped>
/* メイン */
main{
  font-family: "YuGothic";
  font-size: 14px;
}
h1{
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
h2{
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding:40px 0 28.5px 0;
  border-bottom: #777777 solid 1px;
}
h3{
  font-size: 22px;
  font-weight: bold;
}
h4{
  font-size: 20px;
  font-weight: bold;
}
iframe{
  border: 0;
  width: 100%;
  height: 100%;
}
a {
  text-decoration: none;
  color: #707070;
}
/* タイトル */
.mykarte_title{
  background-color: #ffffff;
  padding: 0 40px;
}
.mykarte_title_container{
  padding: 30px 0;
}
.mykarte_title_container p{
  padding-top: 15px;
}
.genre{
  display: inline-block;
  text-align: center;
  font-size: 16px;
  line-height: 50px;
  width: 33.3%;
  border: 1px solid #F5EAE7;
}
.genre_selected{
  background-color: #F5EAE7;
}
</style>
