<template>
  <div
    :class="
      {
        item_categories_btn: !isBlank,
        selected: isSelected && !isBlank,
        isblank: isBlank
      }
    "
  >
    <div
      v-if="!isBlank"
      :class="{selected_child: isSelected}"
      class="item_categories_btn_inner"
    >
      <img
        :src="
          require(
            '@/assets/category_icons/'
              + category.code
              + '.svg'
          )
        "
      >
      <p>
        {{ filteredCategoryMainName }}
      </p>
      <p v-if="filteredCategorySubName">
        {{ filteredCategorySubName }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
    name: "CategorySquare",
    props:{
        category: {
            type: Object
        },
        selected_category: {
            type: String,
            default: ""
        }
    },
    computed:{
        isSelected(){
            return this.selected_category == this.category.name
        },
        filteredCategoryMainName(){
            const bracket_position = this.category.name.indexOf("（")
            if(bracket_position==-1){
                return this.category.name
            }else{
                return this.category.name.substring(0,bracket_position)
            }
        },
        filteredCategorySubName(){
            const bracket_position = this.category.name.indexOf("（")
            if(bracket_position==-1){
                return false
            }else{
                return this.category.name.substring(bracket_position)
            }
        },
        isBlank(){
          return this.category.name == "blank"
        }
    },
}
</script>
<style scoped>
.item_categories_btn {
  /* position: relative; */
  background-color: #fff;
  width: 33%;
  padding-top: 33%;
  position: relative;
  margin-top:5px;
  margin-bottom: 5px;
}
/* .wrapper:before {
    content:"";
    display: block;
    padding-top: 100%;
} */
.item_categories_btn_inner {
  width: 100%;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 40px; */
  padding-bottom: 0px;
  box-sizing: content-box;
}
.item_categories_btn_inner img{
    width: 50%;
    padding-bottom: 10px;
}
.item_categories_btn_inner p{
    font-size: 14px;
}
.selected {
  /* width: 25vmin;
  height: 25vmin; */
  margin-bottom: -15px;
  /* padding-bottom: 40px; */
  background-color: #ffffff;
  box-sizing: content-box;
}
.selected_child{
    top: -15px;
}
.isblank {
  background-color: #F5EAE7;
  width: 33%;
  padding-top: 33%;
  position: relative;
  margin-top:5px;
  margin-bottom: 5px;

}

</style>