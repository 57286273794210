<template>
  <section class="select_condition">
    <div class="concierge_select_btn_container">
      <ul>
        <li
          v-for="concierge in skincareConcierges"
          :key="concierge.id"
          class="concierge_select_btn"
          @click="selectConcierge(concierge.name_lower)"
        >
          <img
            class="concierge_select_btn_img"
            :class="[selectedConcierge.id === concierge.id ? 'selected_concierge_icon' : '']"
            :src="
              ROOT_IMAGE_URL
                + '/face-icon/'
                + concierge.name_lower
                + '.png'
            "
          >
          <p
            :class="[selectedConcierge.id === concierge.id ? 'selected_concierge_name' : '']"
          >
            {{ concierge.name }}
          </p>
        </li>
      </ul>
    </div>
    <div
      v-for="segment in mappedCategories"
      :key="segment"
      class="suggested_item"
    >
      <div class="item_categories_btn_row">
        <category-square
          v-for="(cate, index) in segment"
          :key="cate.id"
          :class="{ is_center_square: index % 3 == 1 }"
          :category="cate"
          :selected_category="selectedCategory"
          @click="changeCategory(cate)"
        />
      </div>
      <skincare-message
        v-if="segment.map((x) => x.name).includes(selectedCategory)"
        :hasMainConcierge="hasMainConcierge"
        :notYetConsult="notYetConsult"
        :itemLength="items.length"
        :resultSet="result_set"
      />
      <item-list
        v-if="segment.map((x) => x.name).includes(selectedCategory)"
        genre="skin-care"
        :category="selectedCategory"
        :items="filteredItems"
      />
    </div>
  </section>
  <div class="guiding_section_inner">
    <div class="guiding_section_toChatbot">
      <router-link
        to="/flow/item-suggestions"
      >
        もう一度コンシェルジュからオススメ商品の提案を受ける
      </router-link>
    </div>
  </div>
</template>
<script>
import axios from "axios"
import liff from "@line/liff"
import CategorySquare from "../../components/CategorySquare.vue";
import ItemList from "../../components/ItemList.vue";
import SkincareMessage from "../MyKarteAreas/SkincareMessage.vue"
const skincareCategories = require("../../data/skincareCategories");
const concierges = require("../../data/concierges")

export default {
  name: "SkinCare",
  components: {
    CategorySquare,
    ItemList,
    SkincareMessage
  },
  data() {
    return {
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
      skincareCategories,
      concierges,
      items: [],
      selectedCategory: '',
      selectedConcierge: concierges[1],
      notYetConsult: false,
      hasMainConcierge: false,
      result_set: {}
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter(
        (item) => item.category == this.selectedCategory
      );
    },
    mappedCategories() {
      const length = Math.ceil(this.skincareCategories.length / 3);
      return new Array(length)
        .fill()
        .map((_, i) => this.skincareCategories.slice(i * 3, (i + 1) * 3));
    },
    skincareConcierges() {
      return this.concierges.filter(
        (concierge) => concierge.genre.includes("skincare")
      )
    }
  },
  methods: {
    getLatestItems() {
      //カテゴリーが選択状態でなければ取得しない
      if(this.selectedCategory=="")
        return
      
      // 基本処理
      const mydata_target_url = process.env.VUE_APP_MYDATA_URL;
      const id_token = liff.getIDToken();
      axios
        .post(mydata_target_url, {
          liff_id: process.env.VUE_APP_LIFF_ID,
          skin_type_diag: false,
          skincare: {
            request: true,
            main_concierge: this.selectedConcierge.name,
            sub_category: this.selectedCategory
          },
          makeup: {request: false},
          id_token: id_token,
        })
        .then((res) => {
          if ("skincare_items" in res.data) {
            this.items = res.data.skincare_items.Items;
            this.$gtag.event('view_item_list', {
              item_list_name: "Skincare MyKarte",
              items: this.convertItemList2GA4(this.filteredItems)
            })
            if(res.data.has_previous_result){
              if(res.data.skincare_items.Items.length>0){
                this.hasMainConcierge = res.data.skincare_items.Items[0].has_main_concierge
              }else{
                this.hasMainConcierge = false
              }
              this.notYetConsult = false
              this.result_set = res.data.result_set
            }else{
              this.notYetConsult = true
            }
          }
        })
        .catch((err) => {
          this.notYetConsult = true
          console.log("Mykarte", err.code, err.message);
        });
    },
    changeCategory(category) {
      if(this.selectedCategory==category.name){
        this.selectedCategory = ""
      }else if(this.selectedCategory==""){
        this.selectedCategory = category.name
      }else{
        //新しく選択されたカテゴリーが下、現在の選択中のカテゴリーが上の場合
        //上のItemListが消失するためその分上に詰める必要がある
        const prevCategoryIndex = this.skincareCategories.map(x=>x.name).indexOf(this.selectedCategory)
        const newCategoryIndex = this.skincareCategories.indexOf(category)
        
        if(Math.floor(prevCategoryIndex/3)<Math.floor(newCategoryIndex/3)){
          const messageWrapper = document.getElementsByClassName("messageWrapper")[0]
          const itemList = document.getElementsByClassName("suggested_item_list")[0]
          window.scrollBy(0, -(messageWrapper.clientHeight+itemList.clientHeight))
        }
        this.selectedCategory = category.name
      }
      this.getLatestItems()
    },
    // コンシェルジュの選択
    selectConcierge(conciergeName) {
      this.selectedConcierge = concierges.find(
        concierge => concierge.name_lower==conciergeName)
      if(this.selectedCategory!=""){
        this.getLatestItems()
      }
    },
    convertItemList2GA4(items) {
      return items.map((item, idx) => {
        return {
          item_id: item.item_id,
          item_name: item.item_name,
          item_brand: item.brand_name,
          item_category: "skincare",
          item_category2: item.category,
          index: idx,
          affiliation: item.has_main_concierge
              ? this.selectedConcierge.name
              : "(" + item.other_concierges.join(",") + ")",
        };
      });
    },
  },
};
</script>
<style scoped>
.suggested_item{
  width: 100%;
}
.item_categories_btn_row {
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding-bottom: 0px;
  margin-bottom: 0px;
  width: 100%;
}
.item_categories_btn_row:last-of-type{
  justify-content: center;
}
.item_categories_btn_container {
  position: relative;
}
.item_categories_btn_inner {
  width: 100%;
}
.item_categories_btn img {
  width: 60px;
  padding: 20px 0;
}
.item_categories_btn p {
  font-size: 14px;
}
.select_condition{
  background-color: #F5EAE7;
  position: relative;
}
.concierge_select_btn_container {
  width: 100%;
  padding-top: 10px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* 慣性スクロール */
  -ms-overflow-style: none;
  /* Firefox 対応 */
  scrollbar-width: none;
}
.concierge_select_btn_container::-webkit-scrollbar {
  display:none;
}
.concierge_select_btn_container ul {
  max-width: 100%; /* メニューの最大幅 */
  min-width: max-content; /* メニューの最小幅 */
  margin: 0 auto;
  list-style: none;
  padding-right: 25px; /* 右側の固定分余白を空ける */
}
.concierge_select_btn{
  float: left;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  padding: 7px 11px;
}
.concierge_select_btn:last-child{
  padding:7px 0 7px 11px;
}
.concierge_select_btn_img{
  width:65px;
  border-radius: 50%;
  border: solid 1px #CCCCCC;
  margin-bottom: 5px;
}
.selected_concierge_icon{
  width: 75px;
  border: solid 2px #F29F8F;
}
.concierge_select_btn ul li p{
  line-height: 30px;
}
/* .scroll_right_btn_c { 
  position: absolute;
  top: 0;
  right: -1em;
  padding: 7px 3px;
  background: #ffffff;
  color: #707070;
  line-height: 65px;
} */
.selected_concierge_name{
  font-size: 16px;
  font-weight: bold;
}
/* .concierge_select_container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;
}
.concierge_select_btn{
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}
.concierge_select_btn_img{
  border-radius: 50%;
  border: solid 1px #CCCCCC;
  width: 60px;
}
.selected_concierge_icon{
  width: 75px;
  border: solid 2px #F29F8F;
}
.selected_concierge_name{
  font-weight: bold;
}
.concierge_select_btn p{
  font-size: 14px;
  padding-top: 5px;
} */
.guiding_section_inner {
  padding: 100px 40px 0;
  text-align: center;
  background-color: #F5EAE7;
}
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
router-link {
 background-color: transparent;
  text-decoration: none;
  color: inherit; 
}
.guiding_section_toChatbot a {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  color: #444444;
  background-color: #fadad2;
  font-weight: bold;
}
.guiding_section_toChatbot {
  padding-bottom: 50px;
}
.is_center_square {
  margin-left: 10px;
  margin-right: 10px;
}
.mappedAnswers {
  display: flex;
  flex-wrap: wrap;
}
.mappedAnswers p {
  padding-right: 10px;
  padding-bottom: 3px;
  font-size: 12px;
}
.noItemCategory{
  background-color: #ffffff;
}
.noItemDescription{
  padding: 0 40px;
  text-align: center;
  margin: 5px 0;
}
.noItemDescription p{
  padding: 30px 0 20px;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}
.noItemDescription a{
  padding: 11px 14px;
  background-color: #FADAD2;
  display: inline-block;
  color: #444444;
  margin-bottom: 15px;
}
</style>
