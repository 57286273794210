<template>
  <div class="messageWrapper">
    <div
      v-if="!notYetConsult"
      class="mappedAnswers_container"
    >
      <div class="mappedAnswers">
        <p
          v-for="answer in mappedAnswers"
          :key="answer"
        >
          {{ answer }}
        </p>
      </div>
    </div>
    <div class="noItemDescription">
      <div
        v-if="notYetConsult"
        class="notYetConsult"
      >
        <p>
          {{ notYetConsultMessage }}
        </p>
        <router-link to="/flow/item-suggestions">
          コンシェルジュに相談する
        </router-link>
      </div>
      <div
        v-else-if="itemLength == 0"
        class="noItem"
      >
        <p>
          {{ noItemMessage }}
        </p>
        <router-link to="/flow/item-suggestions">
          コンシェルジュに相談する
        </router-link>
      </div>
      <div v-else-if="!hasMainConcierge && itemLength > 0">
        <p>
          {{ otherConciergeMessage }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import answerMapper from "../../data/answerMapper";

export default {
  name: "SkincareMessage",
  props: {
    notYetConsult: {
      type: Boolean,
    },
    hasMainConcierge: {
      type: Boolean,
    },
    resultSet: {
      type: Object,
      default: () => {},
    },
    itemLength: {
      type: Number,
    },
  },
  data() {
    return {
      otherConciergeMessage:
        "このコンシェルジュのオススメ商品がありません。他のコンシェルジュのオススメ商品をご提案いたします。",
      noItemMessage:
        "申し訳ございません。あなたが選択した内容でご提案できる商品はございません。",
      notYetConsultMessage:
        "このカテゴリーの商品はまだご相談いただいておりません。",
      answerMapper,
    };
  },
  computed: {
    mappedAnswers() {
      const genre = "skincare";
      let mappedAnswers = [];
      for (let qa of Object.entries(this.resultSet)) {
        if (
          qa[0] in this.answerMapper[genre] &&
          qa[1] in this.answerMapper[genre][qa[0]]
        ) {
          if (this.answerMapper[genre][qa[0]][String(qa[1])] != "")
            mappedAnswers.push(this.answerMapper[genre][qa[0]][String(qa[1])]);
        }
      }
      return mappedAnswers;
    },
  },
};
</script>
<style scoped>
.messageWrapper {
  background-color: #ffffff;
  margin-top: 5px;
  padding-top: 10px;
}
.mappedAnswers_container{
  padding: 0 40px;
}
.mappedAnswers {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}
.mappedAnswers p {
  padding-right: 10px;
  padding-bottom: 3px;
  font-size: 12px;
}
.noItemCategory {
  background-color: #ffffff;
}
.noItemDescription {
  padding: 0 40px;
  text-align: center;
}
.notYetConsult p{
  padding: 30px 0 20px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}
.noItem p {
  padding: 0 0 20px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}
a {
  padding: 11px 14px;
  background-color: #fadad2;
  display: inline-block;
  color: #444444;
  margin-bottom: 20px;
}
</style>